import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
    path: '/',
    name: 'Login',
    component: () =>
        import('../views/Login.vue')
},
{
    path: '/about',
    component: () =>
        import('../views/About.vue')
},
{
    path: '/videoRecord',

    component: () =>
        import('../views/VideoRecord.vue')
},

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


export default router